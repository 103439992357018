import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  TextField,
  Button,
  CardContent,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { sendResetPasswordEmail } from "../redux/authSlice"; 
import ToastNotification, { showSuccessToast, showErrorToast } from "../components/ToastNotification";
import { useNavigate } from "react-router-dom";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(sendResetPasswordEmail({ email }))
      .unwrap()
      .then(() => {
        // showSuccessToast("Reset link sent to your email.");
        setTimeout(() => {
          navigate("/new-password");
        }, 2000);
      })
      .catch(() => {
        showErrorToast("Failed to send reset email. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(135deg, #01AEFE, #42a5f5)",
        padding: "50px 0",
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            background: "#fff",
            borderRadius: "16px",
            boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
            p: 4,
            textAlign: "center",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0 12px 30px rgba(0,0,0,0.3)",
            },
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#1976d2", mb: 3 }}
            >
              Reset Password
            </Typography>

            <Typography
              variant="body1"
              sx={{ color: "#666", mb: 4 }}
            >
              Enter your email address to receive a password reset link.
            </Typography>

            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                type="email"
                sx={{ mb: 3 }}
                required
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
                sx={{
                  backgroundColor: loading ? "#ccc" : "#1976d2",
                  color: "#fff",
                  fontWeight: "bold",
                  py: 1.5,
                  borderRadius: "8px",
                  fontSize: "1.2rem",
                  "&:hover": {
                    backgroundColor: loading ? "#ccc" : "#1565c0",
                  },
                }}
              >
                {loading ? "Sending..." : "Continue"}
              </Button>
            </Box>
          </CardContent>

          <Button
            onClick={() => navigate("/login")}
            sx={{
              textTransform: "none",
              color: "#1976d2",
              fontWeight: "bold",
              mt: 2,
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "transparent",
              },
            }}
          >
            Back to Login
          </Button>
        </Card>
      </Container>
      <ToastNotification />
    </Box>
  );
};

export default ForgetPasswordPage;