import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../Network/NetworkManager';

// Load user and token from localStorage if available
const userFromLocalStorage = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null;

const tokenFromLocalStorage = localStorage.getItem('token')
  ? localStorage.getItem('token')
  : null;

// Async thunk for signing up a user
export const signupUser = createAsyncThunk(
  'auth/signupUser',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post('/auth/signup', formData);
      return response.data; // Return user data after successful signup
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle error
    }
  }
);

// Async thunk for logging in a user
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post('/auth/login', formData);
      return response.data; // Return user data after successful login
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle error
    }
  }
);
export const sendResetPasswordEmail = createAsyncThunk(
  'auth/sendResetPasswordEmail',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await api.post('/auth/forgot-password', { email });
      return response.data; // Return user data after successful login
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle error
    }
  }
);

// Async thunk for updating user profile
export const updateUserProfile = createAsyncThunk(
  'auth/updateUserProfile',
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await api.put('users/profile', formData); // PUT request to update profile
      return response.data; // Assuming the API returns updated user data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle error
    }
  }
);

// Auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: userFromLocalStorage,  // Initialize user from localStorage
    token: tokenFromLocalStorage,  // Initialize token from localStorage
    loading: false,
    error: null,
  },
  reducers: {
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      // Signup user cases
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.user; // Update user state
        state.token = action.payload.data.token; // Update token state
        localStorage.setItem('user', JSON.stringify(state.user));
        localStorage.setItem('token', state.token);
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Login user cases
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.user; // Store user data
        state.token = action.payload.data.token; // Store token
        localStorage.setItem('user', JSON.stringify(state.user));
        localStorage.setItem('token', state.token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update user profile cases
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload; // Assuming the API returns updated user object directly
        localStorage.setItem('user', JSON.stringify(state.user));
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logoutUser } = authSlice.actions;

export default authSlice.reducer;